import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { Product } from '../../models/product';

@Component({
  selector: 'app-cant-delete-product-modal',
  templateUrl: './cant-delete-product-modal.component.html',
  styleUrls: ['./cant-delete-product-modal.component.scss'],
  imports: [FocusableDirective, AssetSrcDirective, TranslatePipe],
})
export class CantDeleteProductModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() product: Product;
  @Input() taskNames: string[] = [];
}
