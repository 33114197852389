import { Component, Input, inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type ServerError } from '@core/models/serverError';

@Component({
  selector: 'app-import-errors-modal',
  templateUrl: './import-errors-modal.component.html',
  styleUrls: ['./import-errors-modal.component.scss'],
  imports: [FocusableDirective, AssetSrcDirective, TranslatePipe],
})
export class ImportErrorsModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly translate = inject(TranslateService);
  @Input() errorData: ServerError[];
  public errorMessages: string[] = [];

  public ngOnInit(): void {
    this.errorData.forEach((error) => {
      if (!error?.data?.fieldErrors?.length) {
        this.errorMessages.push(error.errorMessage);
        return;
      }

      error.data.fieldErrors.forEach((fieldError) => {
        let errorMsg = fieldError.message;

        if (fieldError.errorCode === 'product_has_incompleted_tasks') {
          errorMsg = this.translate.instant('productsImportErrorsModal.errorMessages.cannotChangeVendor');
        }

        this.errorMessages.push(
          this.translate.instant('productsImportErrorsModal.recordError', {
            recordIndex: error.data.recordIndex,
            error: errorMsg,
            property: fieldError.property,
          }),
        );
      });
    });
  }
}
