import { Component, forwardRef, Input, type OnInit, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';

import { type SelectOption } from '@core/components/select/select.component';
import { type TreeviewPickerOption } from '@core/components/treeview-picker/treeview-picker.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { ProductCategoriesSelectorModalComponent } from '@core/modals/product-categories-selector-modal/product-categories-selector-modal.component';
import { EnumService } from '@core/services/enum.service';
import { ModalService } from '@core/services/modal.service';

@UntilDestroy()
@Component({
  selector: 'classification-selector',
  templateUrl: './classification-selector.component.html',
  styleUrls: ['./classification-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClassificationSelectorComponent),
      multi: true,
    },
  ],
  imports: [FocusableDirective, AssetSrcDirective, TranslatePipe],
})
export class ClassificationSelectorComponent implements ControlValueAccessor, OnInit {
  private readonly modalService = inject(ModalService);
  private readonly enumService = inject(EnumService);
  @Input() disabled = false;
  @Input() placeholder: string;

  private categories: TreeviewPickerOption[] = [];

  private _value: SelectOption;
  private onChange = (val: SelectOption) => {};
  private onTouched = () => {};

  public ngOnInit(): void {
    this.enumService
      .getCompanyProductCategories()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.categories = res;
      });
  }

  public get value(): SelectOption {
    return this._value;
  }

  public set value(val: SelectOption) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public openSelector(): void {
    this.modalService
      .open({
        content: ProductCategoriesSelectorModalComponent,
        inputs: {
          options: this.categories,
          singleSelect: true,
          selectedCategories: [this._value],
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((category) => {
        this.value = category[0];
      })
      .catch(() => {});
  }

  public writeValue(value: SelectOption): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
