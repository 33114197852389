@if (!isEmpty) {
  <div class="image-uploader_preview-container">
    @if (imageCrop) {
      <img
        class="image-uploader_preview"
        [src]="imageCrop"
      />
    }
    @if (imageCrop) {
      <button
        [disabled]="isLoading"
        class="edit-image-btn filepond--file-action-button"
        (click)="editImage()"
      ></button>
    }
  </div>
}

@if (isEmpty) {
  <div class="image-uploader_placeholder">
    <img appAssetSrc="assets/svg/image-uploader/empty-preview.svg" />
    <div>
      <p>
        {{ placeholderText | translate }}
        <span>{{ 'imageUploader.browse' | translate }}</span>
      </p>
      @if (placeholderText2) {
        <p>{{ placeholderText2 | translate }}</p>
      }
    </div>
  </div>
}

<file-pond
  [class.preview-mode]="!isEmpty"
  #filePond
  [appFocusable]
  (click)="onOverlayClick()"
  [options]="getPondOptions()"
  (onremovefile)="handleRemoveFile()"
  (onprocessfileabort)="handleUploadAbort()"
  (onprocessfilerevert)="handleUploadAbort()"
  (onaddfilestart)="handleAddFileStart($event)"
  (onaddfile)="handleAddFile($event)"
/>
