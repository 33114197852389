import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { type Task } from '@clover/tasks/models/task';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { ModalService } from '@core/services/modal.service';

import { ProductsService } from '../../products.service';

@UntilDestroy()
@Component({
  selector: 'app-vendor-edit-modal',
  templateUrl: './vendor-edit-modal.component.html',
  styleUrls: ['./vendor-edit-modal.component.scss'],
  imports: [ButtonLoadingDirective, TranslatePipe],
})
export class VendorEditModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly modalService = inject(ModalService);
  private readonly productsService = inject(ProductsService);
  @Input() tasks: Task[];

  public isLoading = false;
  public errorMessages: string[] = [];

  public closeTasks(): void {
    this.isLoading = true;
    this.productsService
      .closeTasksForProduct(this.tasks.map((task) => task.id))
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.activeModal.close();
        }),
        catchError((err) => {
          this.activeModal.dismiss();
          this.modalService.open({
            content: OkModalComponent,
            inputs: {
              isError: true,
              title: 'vendorEditModal.unableToClose',
              text: 'vendorEditModal.unableToCloseMsg',
            },
          });
          return of(err);
        }),
      )
      .subscribe();
  }
}
