<div class="product-edit-page_header">
  <h1>
    {{ isEdit ? product.name : ('productEditPage.newProduct' | translate) }}
  </h1>
  @if (!isEdit) {
    <p>{{ 'productEditPage.newProductMsg' | translate }}</p>
  }
</div>

<div class="product-edit-page_body">
  <form [formGroup]="form">
    <div class="product-edit_block">
      <div class="product-edit_images">
        <app-product-images
          [ngClass]="{ 'has-error': isFormSubmitted && imagesError }"
          [(images)]="images"
        />
        @if (imagesError) {
          <div class="form-control-error">
            {{ imagesError | translate }}
          </div>
        }
      </div>
      <div>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('name').invalid,
          }"
        >
          <label class="required">
            {{ 'productEditPage.controls.name.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="name"
            id="name"
            placeholder="{{ 'productEditPage.controls.name.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('name')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('packSize').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.packSize.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="packSize"
            id="packSize"
            placeholder="{{ 'productEditPage.controls.packSize.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('packSize')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('marketingMessage').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.marketingMessage.label' | translate }}
          </label>
          <rich-text-editor
            formControlName="marketingMessage"
            id="text"
            [textOnly]="true"
            [linkOption]="false"
            placeholder="{{ 'productEditPage.controls.marketingMessage.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('marketingMessage')"
          />
        </div>
      </div>
    </div>

    <h3>{{ 'productEditPage.productIdentifiers' | translate }}</h3>

    <div class="product-edit_block">
      <div>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('internalItemNumber').invalid,
          }"
        >
          <label class="required">
            {{ 'productEditPage.controls.itemNumber.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="internalItemNumber"
            placeholder="{{ 'productEditPage.controls.itemNumber.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('internalItemNumber')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('globalTradeIdentificationNumber').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.gtin.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="globalTradeIdentificationNumber"
            id="globalTradeIdentificationNumber"
            placeholder="{{ 'productEditPage.controls.gtin.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('globalTradeIdentificationNumber')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('brandName').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.brandName.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="brandName"
            id="brandName"
            placeholder="{{ 'productEditPage.controls.brandName.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('brandName')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('accessType').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.accessType.label' | translate }}
          </label>
          <app-select
            placeholder="common.buttons.select"
            formControlName="accessType"
            [returnFullOption]="true"
            [options]="accessTypes"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('accessType')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('categoryCodes').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.categoryCodes.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="categoryCodes"
            id="categoryCodes"
            placeholder="{{ 'productEditPage.controls.categoryCodes.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('categoryCodes')"
          />
        </div>
      </div>

      <div>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('manufactureProductCode').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.mpc.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="manufactureProductCode"
            id="manufactureProductCode"
            placeholder="{{ 'productEditPage.controls.mpc.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('manufactureProductCode')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('globalProductClassification').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.gpc.label' | translate }}
          </label>
          <classification-selector
            formControlName="globalProductClassification"
            placeholder="{{ 'productEditPage.controls.gpc.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('globalProductClassification')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('department').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.department.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="department"
            id="department"
            placeholder="{{ 'productEditPage.controls.department.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('department')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('productId').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.productId.label' | translate }}
          </label>
          <input
            autocomplete="false"
            type="text"
            formControlName="productId"
            id="productId"
            placeholder="{{ 'productEditPage.controls.productId.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('productId')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': isFormSubmitted && form.get('vendor').invalid,
          }"
        >
          <label>
            {{ 'productEditPage.controls.vendor.label' | translate }}
          </label>
          <company-selector
            formControlName="vendor"
            [customOpenFunc]="vendorEditClick"
            [disabled]="isIndividualCompany"
            [showPremiumFeatureModal]="isIndividualCompany"
            nonConnectedCompanyAction="connect"
            placeholder="{{ 'productEditPage.controls.vendor.placeholder' | translate }}"
          />
          <form-error-messages
            [showErrors]="isFormSubmitted"
            [control]="form.get('vendor')"
          />
        </div>
      </div>
    </div>

    <div class="product-edit_block">
      <div>
        <h3>
          {{ 'productEditPage.productDimensions' | translate }}
        </h3>

        <div
          class="form-row"
          formGroupName="length"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('length.value').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.length.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="value"
              id="lengthValue"
              mask="99999999999999.00"
              [dropSpecialCharacters]="false"
              placeholder="{{ 'productEditPage.controls.length.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('length.value')"
            />
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('length.unitOfMeasure').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.unitOfMeasure.label' | translate }}
            </label>
            <app-select
              placeholder="common.buttons.select"
              formControlName="unitOfMeasure"
              [returnFullOption]="true"
              [options]="unitsOfMeasure"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('length.unitOfMeasure')"
            />
          </div>
        </div>

        <div
          class="form-row"
          formGroupName="width"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('width.value').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.width.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="value"
              id="widthValue"
              mask="99999999999999.00"
              [dropSpecialCharacters]="false"
              placeholder="{{ 'productEditPage.controls.width.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('width.value')"
            />
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('width.unitOfMeasure').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.unitOfMeasure.label' | translate }}
            </label>
            <app-select
              placeholder="common.buttons.select"
              formControlName="unitOfMeasure"
              [returnFullOption]="true"
              [options]="unitsOfMeasure"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('width.unitOfMeasure')"
            />
          </div>
        </div>

        <div
          class="form-row"
          formGroupName="height"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('height.value').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.height.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="value"
              id="heightValue"
              mask="99999999999999.00"
              [dropSpecialCharacters]="false"
              placeholder="{{ 'productEditPage.controls.height.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('height.value')"
            />
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('height.unitOfMeasure').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.unitOfMeasure.label' | translate }}
            </label>
            <app-select
              placeholder="common.buttons.select"
              formControlName="unitOfMeasure"
              [returnFullOption]="true"
              [options]="unitsOfMeasure"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('height.unitOfMeasure')"
            />
          </div>
        </div>
      </div>

      <div>
        <h3>
          {{ 'productEditPage.productTemperatureZone' | translate }}
        </h3>

        <div
          class="form-row"
          formGroupName="storageTemperatureMinimum"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('storageTemperatureMinimum.value').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.temperatureMin.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="value"
              mask="99999999999999.00"
              [dropSpecialCharacters]="false"
              id="storageTemperatureMinimumValue"
              placeholder="{{ 'productEditPage.controls.temperatureMin.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('storageTemperatureMinimum.value')"
            />
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('storageTemperatureMinimum.unitOfMeasure').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.unitOfMeasure.label' | translate }}
            </label>
            <app-select
              placeholder="common.buttons.select"
              formControlName="unitOfMeasure"
              [returnFullOption]="true"
              [options]="unitsOfMeasureTemperature"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('storageTemperatureMinimum.unitOfMeasure')"
            />
          </div>
        </div>

        <div
          class="form-row"
          formGroupName="storageTemperatureMaximum"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('storageTemperatureMaximum.value').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.temperatureMax.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="value"
              mask="99999999999999.00"
              [dropSpecialCharacters]="false"
              id="storageTemperatureMaximumValue"
              placeholder="{{ 'productEditPage.controls.temperatureMax.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('storageTemperatureMaximum.value')"
            />
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && form.get('storageTemperatureMaximum.unitOfMeasure').invalid,
            }"
          >
            <label>
              {{ 'productEditPage.controls.unitOfMeasure.label' | translate }}
            </label>
            <app-select
              placeholder="common.buttons.select"
              formControlName="unitOfMeasure"
              [returnFullOption]="true"
              [options]="unitsOfMeasureTemperature"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="form.get('storageTemperatureMaximum.unitOfMeasure')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="product-edit-page_buttons">
      <button
        type="button"
        class="btn secondary-btn"
        (click)="back()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
      <button
        type="button"
        [appBtnLoading]="isLoading"
        class="btn btn-primary submit-btn"
        (click)="save()"
      >
        {{ 'common.buttons.save' | translate }}
      </button>
    </div>
  </form>
</div>
