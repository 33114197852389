<div class="modal-body">
  <button
    (click)="activeModal.dismiss()"
    [appFocusable]
    aria-label="Close"
    class="close-btn"
    type="button"
  >
    <img
      alt="{{ 'common.buttons.close' | translate }}"
      height="18"
      ngSrc="assets/svg/common/cross.svg"
      width="18"
    />
  </button>

  @if (!loading) {
    <h1>{{ 'productsImportModal.importProducts' | translate }}</h1>

    <p>
      <span>{{ 'productsImportModal.importCompaniesText' | translate }}</span>
      <a
        [href]="templateUrl"
        target="_blank"
      >
        {{ 'productsImportModal.productUploadTemplate' | translate }}
      </a>
      <span>. {{ 'productsImportModal.fileFormat' | translate }}</span>
    </p>

    <div class="import-products-modal_save-to-worklist">
      <app-toggle [(ngModel)]="saveToWorklist" />
      <div>{{ 'productsImportModal.saveToWorklist' | translate }}</div>
    </div>

    <form [formGroup]="form">
      <div
        class="form-group"
        [ngClass]="{ 'has-error': form.invalid }"
      >
        <label for="worklistNameInput">
          {{ 'productsImportModal.controls.worklistName.label' | translate }}
        </label>

        <input
          id="worklistNameInput"
          autocomplete="false"
          type="text"
          formControlName="worklistName"
          placeholder="{{ 'productsImportModal.controls.worklistName.placeholder' | translate }}"
        />

        <form-error-messages [control]="worklistNameControl" />
      </div>
    </form>
  } @else {
    <h1>{{ 'productsImportModal.importProducts' | translate }}</h1>

    <h4>
      {{ 'productsImportModal.uploadingFileText' | translate }}
    </h4>

    <div class="import-products-modal_loader">
      <div class="loader"></div>
    </div>
  }

  <div class="buttons-group">
    @if (!loading) {
      <button
        [disabled]="form.invalid"
        class="btn btn-primary submit-btn"
        (click)="startImportFlow()"
      >
        {{ 'common.buttons.import' | translate }}
      </button>
    } @else {
      <button
        class="btn btn-primary submit-btn"
        (click)="cancelUpload()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
    }
  </div>
</div>
