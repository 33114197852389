<div class="product-edit-page_header">
  <h1>{{ product.name }}</h1>

  <div
    class="product-view-page_description"
    innerHtml="{{ description }}"
  ></div>
</div>

<div class="product-edit-page_body">
  <div class="tabs">
    <div
      [appFocusable]
      class="tabs_tab"
      [class.active]="activeTab === tabs.basic"
      (click)="activeTab = tabs.basic"
    >
      {{ 'productEditPage.tabs.basic' | translate }}
    </div>

    <div
      [appFocusable]
      class="tabs_tab"
      [class.active]="activeTab === tabs.files"
      (click)="activeTab = tabs.files"
    >
      {{ 'productEditPage.tabs.files' | translate }}
    </div>
    <div
      [appFocusable]
      class="tabs_tab"
      [class.active]="activeTab === tabs.taskHistory"
      (click)="activeTab = tabs.taskHistory"
    >
      {{ 'productEditPage.tabs.taskHistory' | translate }}
    </div>
  </div>

  @if (activeTab === tabs.basic) {
    <div class="product-edit_block">
      <div>
        <app-product-images
          [readonly]="true"
          [(images)]="images"
        />
      </div>
      <div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.name.label' | translate }}
          </label>
          <div>
            {{ product.name }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.packSize.label' | translate }}
          </label>
          <div>
            {{ product.packSize }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.marketingMessage.label' | translate }}
          </label>
          <div>
            <rich-text-editor
              #marketingMessage
              [readOnly]="true"
              [textOnly]="true"
              [(ngModel)]="marketingMsg"
            />
          </div>
        </div>
      </div>
    </div>
    <h3>{{ 'productEditPage.productIdentifiers' | translate }}</h3>
    <div class="product-edit_block">
      <div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.gtin.label' | translate }}
          </label>
          <div>
            {{ product.globalTradeIdentificationNumber }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.brandName.label' | translate }}
          </label>
          <div>
            {{ product.brandName }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.itemNumber.label' | translate }}
          </label>
          <div>
            {{ product.internalItemNumber }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.accessType.label' | translate }}
          </label>
          <div>
            {{ product.accessType.title }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.categoryCodes.label' | translate }}
          </label>
          <div>
            {{ product.categoryCodes }}
          </div>
        </div>
      </div>
      <div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.mpc.label' | translate }}
          </label>
          <div>
            {{ product.manufactureProductCode }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.gpc.label' | translate }}
          </label>
          <div>
            {{ product.globalProductClassification?.category?.title }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.department.label' | translate }}
          </label>
          <div>
            {{ product.department }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.productId.label' | translate }}
          </label>
          <div>
            {{ product.productId }}
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.vendor.label' | translate }}
          </label>
          <div>
            {{ product.vendor?.companyName || product.vendor?.internalName }}
          </div>
        </div>
      </div>
    </div>
    <div class="product-edit_block">
      <div>
        <h3>
          {{ 'productEditPage.productDimensions' | translate }}
        </h3>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.length.label' | translate }}
          </label>
          <div>
            @if (product.length) {
              <span>
                {{ product.length?.value | numberFormat }}
                {{ product.length?.unitOfMeasure?.title }}
              </span>
            }
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.width.label' | translate }}
          </label>
          <div>
            @if (product.width) {
              <span>
                {{ product.width?.value | numberFormat }}
                {{ product.width?.unitOfMeasure?.title }}
              </span>
            }
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.height.label' | translate }}
          </label>
          <div>
            @if (product.height) {
              <span>
                {{ product.height?.value | numberFormat }}
                {{ product.height?.unitOfMeasure?.title }}
              </span>
            }
          </div>
        </div>
      </div>
      <div>
        <h3>
          {{ 'productEditPage.productTemperatureZone' | translate }}
        </h3>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.temperatureMin.label' | translate }}
          </label>
          <div>
            @if (product.storageTemperatureMinimum) {
              <span>
                {{ product.storageTemperatureMinimum?.value | numberFormat }}
                {{ product.storageTemperatureMinimum?.unitOfMeasure?.title }}
              </span>
            }
          </div>
        </div>
        <div class="product-view-page_info-block">
          <label class="label-grey">
            {{ 'productEditPage.controls.temperatureMax.label' | translate }}
          </label>
          <div>
            @if (product.storageTemperatureMaximum) {
              <span>
                {{ product.storageTemperatureMaximum?.value | numberFormat }}
                {{ product.storageTemperatureMaximum?.unitOfMeasure?.title }}
              </span>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="product-edit-page_buttons">
      <button
        type="button"
        class="btn secondary-btn"
        [disabled]="!permissions.Product_Delete"
        (click)="delete()"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
      <button
        type="button"
        [disabled]="!permissions.Product_Edit"
        class="btn btn-primary submit-btn"
        (click)="edit()"
      >
        {{ 'common.buttons.edit' | translate }}
      </button>
    </div>
  }

  @if (activeTab === tabs.files) {
    <company-files
      [loadUrl]="filesUrl"
      [hideControls]="false"
      [isProductFiles]="true"
    />
  }

  @if (activeTab === tabs.taskHistory) {
    <product-task-history [product]="product" />
  }
</div>
