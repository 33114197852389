import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
  inject,
  output,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import {
  ImageUploaderComponent,
  ImageUploaderComponent as ImageUploaderComponent_1,
} from '@core/components/image-uploader/image-uploader.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { OkCancelModalComponent } from '@core/modals/ok-cancel-modal/ok-cancel-modal.component';
import { type UploadedImageData } from '@core/models/user';
import { ModalService } from '@core/services/modal.service';

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [FocusableDirective, ImageUploaderComponent_1, AssetSrcDirective, TranslatePipe],
})
export class ProductImagesComponent implements OnInit, AfterViewInit {
  private readonly element = inject(ElementRef);
  private readonly modalService = inject(ModalService);
  @ViewChild('imageUploader') imageUploader: ImageUploaderComponent;
  @Input() readonly = false;
  @Input() images: UploadedImageData[] = [];
  imagesChange = output<UploadedImageData[]>();

  public isLoading = false;
  public imageSlots: Array<{ data: UploadedImageData; preview: string }> = [null, null, null, null];

  public currentSlot = 0;

  public ngOnInit(): void {
    this.images.forEach((img, index) => {
      if (!img) {
        return;
      }
      this.imageSlots[index] = { data: img, preview: img.logoUrl };
    });
  }

  public ngAfterViewInit(): void {
    const area = this.element.nativeElement.querySelector('.product-images_image-area');
    area.ondrop = this.onImageAreaDrop.bind(this, area);
    area.ondragenter = this.onDragEnter.bind(this, area);
    area.ondragleave = this.onDragLeave.bind(this, area);
    area.ondragover = this.onDragOver.bind(this);

    const slots = this.element.nativeElement.querySelectorAll('.product-images_slot');
    slots.forEach((slot) => {
      slot.ondrop = this.onImageSlotDrop.bind(this, slot);
      slot.ondragenter = this.onDragEnter.bind(this, slot);
      slot.ondragleave = this.onDragLeave.bind(this, slot);
      slot.ondragover = this.onDragOver.bind(this);
    });
  }

  public onUploadStart(): void {
    this.isLoading = true;
  }

  public onUploadAbort(): void {
    this.isLoading = false;
  }

  public onImageChange(data: UploadedImageData) {
    this.isLoading = false;
    if (!data) {
      return;
    }

    this.imageSlots[this.currentSlot] = {
      data,
      preview: this.imageUploader.imageCrop,
    };

    this.imagesChange.emit(this.imageSlots.map((slot) => (slot?.data ? slot.data : null)));
  }

  public editImage(): void {
    this.imageUploader.changeImage();
  }

  public changeActiveSlot(index: number): void {
    if (this.readonly && !this.imageSlots[index]) {
      return;
    }

    if (!this.isLoading) {
      this.currentSlot = index;
      this.imageUploader.handleRemoveFile();
    }
  }

  public deleteImage(): void {
    this.modalService
      .open({
        content: OkCancelModalComponent,
        inputs: {
          title: 'productImages.confirmDeletion',
          text: 'productImages.confirmDeletionMsg',
        },
      })
      .result.then(() => {
        this.imageUploader.handleRemoveFile();
        this.imageSlots[this.currentSlot] = null;
        this.imagesChange.emit(this.imageSlots.map((slot) => (slot?.data ? slot.data : null)));
      })
      .catch(() => {});
  }

  public onImageAreaDrop(element: HTMLElement, event: DragEvent): void {
    event.preventDefault();
    element.classList.remove('highlight');
    if (this.isLoading || this.readonly || !!this.imageSlots[this.currentSlot]) {
      return;
    }

    this.imageUploader.startFileUpload(event.dataTransfer.files[0]);
  }

  public onImageSlotDrop(element: HTMLElement, event: DragEvent): void {
    event.preventDefault();
    element.classList.remove('highlight');
    const slotId = +element.getAttribute('slotId');
    if (this.isLoading || this.readonly || !slotId || !!this.imageSlots[slotId]) {
      return;
    }

    this.currentSlot = slotId;
    this.imageUploader.startFileUpload(event.dataTransfer.files[0]);
  }

  public onDragEnter(element: HTMLElement, event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const slotId = +element.getAttribute('slotId') || this.currentSlot;
    if (!this.isLoading && !this.readonly && !this.imageSlots[slotId]) {
      element.classList.add('highlight');
    }
  }

  public onDragLeave(element: HTMLElement, event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isLoading && !this.readonly && !element.contains(event.relatedTarget as HTMLElement)) {
      element.classList.remove('highlight');
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }
}
