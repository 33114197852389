<div class="product-images_slots">
  @for (image of imageSlots; track image; let i = $index) {
    <div
      class="product-images_slot"
      [appFocusable]
      (click)="changeActiveSlot(i)"
      [class.active]="currentSlot === i"
      [class.disabled]="readonly && !image"
      [attr.slotId]="i"
    >
      @if (!image) {
        <div class="product-images_placeholder">
          <span>{{ 'productImages.noImage' | translate }}</span>
        </div>
      }
      @if (image) {
        <img [src]="image.preview" />
      }
    </div>
  }
</div>
<div
  class="product-images_image-area"
  [class.readonly]="readonly"
>
  <image-uploader
    [class.hidden]="imageSlots[currentSlot]"
    placeholderText="productImages.dragProduct"
    #imageUploader
    (uploadedImage)="onImageChange($event)"
    (uploadStarted)="onUploadStart()"
    (uploadAborted)="onUploadAbort()"
  />
  @if (imageSlots[currentSlot]) {
    <div class="product-images_image-container">
      <img [src]="imageSlots[currentSlot].preview" />
    </div>
  }
  @if (!readonly && imageSlots[currentSlot]) {
    <div class="product-images_actions">
      <img
        [appFocusable]
        [appAssetSrc]="'assets/svg/products/pencil-icon.svg'"
        (click)="editImage()"
      />
      <img
        [appFocusable]
        [appAssetSrc]="'assets/svg/products/delete-icon.svg'"
        (click)="deleteImage()"
      />
    </div>
  }
</div>
